
import { onAuthStateChanged } from 'firebase/auth';
import Header from './Header';
import { firebaseAuth, storage } from '../utils/firebase-config';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import qr1 from "../assets/qr1.png";
import qr2 from "../assets/qr2.png";
import qr3 from "../assets/qr3.png";
import qr4 from "../assets/qr4.png";
import axios from 'axios';

function Comprar() {

const navigate = useNavigate();
const [current, setCurrent] = useState('');

const [imageUpload, setImageUpload] = useState();

const [qrcodeimg, setQrcodeimg] = useState('');
const [qrtext, setQrText] = useState('');

const uploadFile = () => {
  if (!imageUpload) return;

  const imageRef = ref(storage, `clientesMidias/${current}/videoVerificacao`);

  uploadBytes(imageRef, imageUpload).then((snapshot) => {
      getDownloadURL(snapshot.ref).then((url) => {
          alert('Video enviado com sucesso! Aguade aprovação da nossa equipe.');
      });
  }  );

};



  var headers = {
      'Content-Type': 'application/json;charset=UTF-8',
      "Access-Control-Allow-Origin": "*",
  }

  async function getDatas(params) {
    try {
      const res = await axios.post('https://api-main-studio.vercel.app/pix', { email: current });
  
      // Check if the response data structure is as expected
      if (res.data && res.data.abacate && Array.isArray(res.data.abacate.qr_codes) && res.data.abacate.qr_codes.length > 0) {
        const firstQrCode = res.data.abacate.qr_codes[0];
  
        if (firstQrCode.links && Array.isArray(firstQrCode.links) && firstQrCode.links.length > 0) {
          setQrcodeimg(firstQrCode.links[0].href);
        } else {
          console.error('No links available in the first QR code');
          // Set a fallback or default value if necessary
          setQrcodeimg(null);
        }
  
        if (firstQrCode.text) {
          setQrText(firstQrCode.text);
        } else {
          console.error('No text available in the first QR code');
          // Set a fallback or default value if necessary
          setQrText(null);
        }
  
      } else {
        console.error('QR codes array is empty or not present in the response');
        // Set fallback or default values if necessary
        setQrcodeimg(null);
        setQrText(null);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      // Handle the error appropriately, maybe set default values
      setQrcodeimg(null);
      setQrText(null);
    }
  }
  

useEffect(()=> {


  
},[])






  onAuthStateChanged(firebaseAuth, (currentUser) => {
    if (currentUser) {setCurrent(currentUser.email);
    console.log('current ', current);
    };
  });
  







  return (
<>
<Header/>

<div className="demo10">
  <div className="container">
      <h4 className="py-4 text-center"
      style={{color: '#dcc780', fontSize: '30px', fontWeight: '800'}}
    
   >Pagamento por Pix</h4>
      <div className="row"
      style={{justifyContent: 'space-evenly'}}

      >
      
   
          <div className="col-md-8 col-sm-6">
              <div className="pricingTable20">
                  <div className="pricingTable-header">
                      <h3 className="heading">Pacote Subidas</h3>
                  
                  </div>
                  <div className="pricing-content">
                      <ul>
                          <li style={{paddingBottom : '50px', fontWeight: '200'}}>
                            Aproxime seu celular para efetuar o pagamento, ou pague pelo código <br></br>
                          <span style={{fontWeight: '600', textDecoration: 'underline', cursor: 'pointer'}}
                          onClick={()=> getDatas()}
                          >{`Pagar com PIX`}</span>
                        <br/> <br/>
                        



                            { qrcodeimg.length > 2 &&
                        <img src={qrcodeimg} style={{width: '300px', height: '300px'}} onClick={()=> getDatas()}/>

                            }
                       






                          </li>
                    
                          <br></br>
                          { qrcodeimg.length > 2 &&
                          <h5 style={{fontWeight: 600}}>Código:</h5>
}
                          <br></br>
                       
                                                    {qrtext}
                                                    <br></br>
                          <br></br>
                      </ul>
           
                  </div>
              </div>
          </div>
      </div>
  </div>
</div>


</>

  
  );
}

export default Comprar;
