import './Excluir.css';
import img11 from '../assets/local_icon.png';
import img22 from '../assets/trash.png';
import img33 from '../assets/local_icon.png';
import imgtrash from '../assets/trash.png';
import imicon from '../assets/local_icon.png';
import Header from './Header';
import { deleteDoc, doc, getDoc, updateDoc } from 'firebase/firestore';
import { db, firebaseAuth } from '../utils/firebase-config';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import imgAlerta from '../assets/alertpng.png';

import axios from 'axios';
import { getAuth, deleteUser, signOut } from "firebase/auth";

var runtime = 0;
function Excluir() {

  const [datas, setDatas] = useState([]);
  const [btnAlerta, setBtnAlerta] = useState(false);
  const queryParameters = new URLSearchParams(window.location.search)
  const type = queryParameters.get("email")


const navigate = useNavigate();

  const fetchClass = async () => {
    

      var transfer = [];
      var dadosinter = setInterval( async () => {
      const classRef = doc(db, "clientes", type);
      const classSnap = await getDoc(classRef);
      transfer.push(classSnap.data());
      if(transfer.length > 0){
        clearInterval(dadosinter);
        trySetData(transfer);
        
      }
    }, 1000);
  
    


  }
function trySetData(params) {
  if(params != undefined){
    setDatas(params[0]);
  }
 
}




async function deleteD(params) {

   
  alert('Conta sendo excluída, aguarde confirmação!')


  const auth = getAuth();
  const user = auth.currentUser;
  
  deleteUser(user).then( async () => {
    // await updateDoc(doc(db, 'clientes', `${user.email}`), {
    //   ativo: false
    // });

    await deleteDoc(doc(db, 'clientes', `${user.email}`));

    // fazer logout
    await signOut(firebaseAuth);

    setTimeout(() => {
      alert('Conta excuída com sucesso!');
      navigate('/');
    }, 2000);

  }).catch((error) => {
    alert('Erro!');
    // ...
  });
}

  useEffect(() => {
      fetchClass();
  }, []);

  return (
    <>
  <Header/>

<div className='hidecell'></div>
  <h4 className="py-4 text-center"
      style={{color: '#dcc780', fontSize: '30px', fontWeight: '800', marginTop: '125px'}}
    
   >Excluir Anúncio</h4>

   {
    btnAlerta &&
    <div style={{zIndex: '100', display: 'flex', flexFlow: 'column nowrap', alignItems: 'center',  justifyContent: 'space-evenly', position: 'fixed', backgroundColor: '#FCFCFC', border: '1px solid rgba(100,100,10, 0.2)', height: '300px', width: '75%', top: '30%', marginLeft: '-37.5%', left: '50%', borderRadius: '30px', }}>
    <div><img src={imgAlerta} width={'80px'}></img></div>
    <div style={{textAlign: 'center'}}><h4 style={{color: '#DCC780'}}>Lembrando que nós NÃO reembolsamos o valor do plano caso ainda estiver no prazo de contratação.</h4></div>
    <div style={{display: 'flex', flexFlow: 'row wrap', justifyContent: 'center'}}>
    <button onClick={()=> setBtnAlerta(false)} style={{margin: '10px'}} className='btn button'>Voltar</button>
    <button onClick={()=> deleteD()} style={{margin: '10px'}} className='btn button'>Excluir</button>
     </div>
    </div>
   }
 

    {
      datas.ativo ?
      <div  >
      <div className="mycontainer" >
        <div className="card-ac" style={{backgroundImage: `url(${datas.url01})`}}>
          <div className="contact">
            <div>
              {
         <p className="name">{datas.nome.split(' ')[0]}</p>
              }
          
              <div
               style={{display: 'flex', alignItems: 'center'}}
               >
                <img 
               src={img11} 
                alt="" className="local"
                
                />
                <p className="city">{datas.cidade}</p>
              </div>
            </div>
            <img 
            src={img22}
            onClick={()=> setBtnAlerta(true)}
            style={{cursor: 'pointer'}}
  
            alt="" className="whats" 
            />
          </div>
        </div>
    
    
    
      </div>
    </div>
      : 

      <div className="content" style={{marginTop: '-130px'}}>
    </div>



    }

 
  </>
  );
}

export default Excluir;






