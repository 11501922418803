import { useNavigate } from 'react-router-dom';
import './AreaAnunciante.css';
import Header from './Header';


function AreaAnunciante() {
  const navigate = useNavigate();
  return (
   <div>
    <Header/>
<div class="publicar">
  <h1 class="titulo">Publicar Anúncio</h1>

  <button class="criar"
  style={{width: '100%'}}
    onClick={()=> navigate('/register')}
  >Criar uma conta</button>
  <button
      onClick={()=> navigate('/login')}
  class="login" style={{paddingRight: '28px', width: '100%'}}>Login</button>
</div>
</div>
  );
}

export default AreaAnunciante;






