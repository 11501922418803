
import './Register.css';
import React, { useEffect, useState } from 'react'
import { db, firebaseAuth, storage } from "../utils/firebase-config";
import { createUserWithEmailAndPassword, onAuthStateChanged } from "firebase/auth";
import { getDownloadURL, ref, uploadBytes } from 'firebase/storage';
import { doc, setDoc, updateDoc } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import Header from './Header';


// CRIAR ANÙNCIO COM CONTA EXTRA




function AtualizarExtra() {

    const navigate = useNavigate();




   
    const queryParameters = new URLSearchParams(window.location.search)
    const type = queryParameters.get("email")
  






    const [deslogado, setDeslogado] = useState(false);

    const [stepby, setStepby] = useState('none')
    const [page01, setPage01] = useState('none');
    const [page02, setPage02] = useState('block');
    const [page03, setPage03] = useState('none');
    const [page04, setPage04] = useState('none');
    const [page05, setPage05] = useState('none');
    const [page06, setPage06] = useState('none');
    const [page07, setPage07] = useState('none');

    const [emaill, setEmaill] = useState('');
    const [senha, setSenha] = useState('');
    const [nome, setNome] = useState('');
    const [cidade, setCidade] = useState('');
    const [idade, setIdade] = useState('');
    const [telefone, setTelefone] = useState('');
    const [sobre, setSobre] = useState('');

    const [precoHr, setPrecoHr] = useState('');
    const [credito, setCredito] = useState(false);
    const [pix, setPix] = useState(false);
    const [dinheiro, setDinheiro] = useState(false);
    const [ligacao, setLigacao] = useState(false);
    const [whatsapp, setWhatsapp] = useState(false);
    const [videochamada, setVideochamada] = useState(false);

    const [branca, setBranca] = useState(false);
    const [latina, setLatina] = useState(false);
    const [mulata, setMulata] = useState(false);
    const [negra, setNegra] = useState(false);
    const [oriental, setOriental] = useState(false);
    const [loira, setLoira] = useState(false);
    const [morena, setMorena] = useState(false);
    const [ruiva, setRuiva] = useState(false);
    const [alta, setAlta] = useState(false);
    const [mignon, setMignon] = useState(false);
    const [gordinha, setGordinha] = useState(false);
    const [magra, setMagra] = useState(false);
    const [silicone, setSilicone] = useState(false);
    const [natural, setNatural] = useState(false);
    const [peluda, setPeluda] = useState(false);
    const [depilada, setDepilada] = useState(false);



    const [beijo, setBeijo] = useState(false);
    const [duplas, setDuplas] = useState(false);
    const [ejaculacaoCorpo, setEjaculacaoCorpo] = useState(false);
    const [facial, setFacial] = useState(false);
    const [fantasias, setFantasias] = useState(false);
    const [massagem, setMassagem] = useState(false);
    const [namoradinha, setNamoradinha] = useState(false);
    const [oralAteFinal, setOralAteFinal] = useState(false);
    const [oralCamisinha, setOralCamisinha] = useState(false);
    const [oralSemCamisinha, setOralSemCamisinha] = useState(false);
    const [pse, setPse] = useState(false);
    const [sexoAnal, setSexoAnal] = useState(false);

    const [ativo, setAtivo] = useState(true);

    const [beijoNegro, setBeijoNegro] = useState(false);
    const [chuvaDourada, setChuvaDourada] = useState(false);
    const [chuvaNegra, setChuvaNegra] = useState(false);
    const [fetichismo, setFetichismo] = useState(false);
    const [sadoDuro, setSadoDuro] = useState(false);
    const [squirting, setSquirting] = useState(false);
    const [gargantaProfunda, setGargantaProfunda] = useState(false);
    const [sadoSuave, setSadoSuave] = useState(false);
    const [strapOn, setStrapOn] = useState(false);


    const [domicilio, setDomicilio] = useState(false);
    const [swing, setSwing] = useState(false);
    const [comLocal, setComLocal] = useState(false);
    const [despedidas, setDespedidas] = useState(false);
    const [festasEventos, setFestasEventos] = useState(false);
    const [jantarRomantico, setJantarRomantico] = useState(false);
    const [hotel, setHotel] = useState(false);
    const [viagens, setViagens] = useState(false);

    const [imageUpload, setImageUpload] = useState();
    const [imageUpload2, setImageUpload2] = useState();
    const [imageUpload3, setImageUpload3] = useState();
    const [imageUpload4, setImageUpload4] = useState();
    const [imageUpload5, setImageUpload5] = useState();
    const [imageUpload6, setImageUpload6] = useState();
    const [imageUpload7, setImageUpload7] = useState();
    const [imageUpload8, setImageUpload8] = useState();
    const [imageUpload9, setImageUpload9] = useState();
    const [videoUpload, setVideoUpload] = useState();

    const [url01, setUrl01] = useState('');
    const [url02, setUrl02] = useState('');
    const [url03, setUrl03] = useState('');
    const [url04, setUrl04] = useState('');
    const [url05, setUrl05] = useState('');
    const [url06, setUrl06] = useState('');
    const [url07, setUrl07] = useState('');
    const [url08, setUrl08] = useState('');
    const [url09, setUrl09] = useState('');
    const [url10, setUrl10] = useState('');

    const [msgs, setMsgS] = useState('');






    async function setMaster(params) {
        
        if(params == 1){
        

            try {
              
              await createUserWithEmailAndPassword(firebaseAuth, emaill, senha);
               setPage01('none');
               setPage02('block');
               setPage03('none');
               setPage04('none');
              } catch (err) {
                setMsgS(err.code);
              }


        }
        if(params == 2){
            setPage01('none');
            setPage02('none');
            setPage03('block');
            setPage04('none');
        }

        if(params == 3){
            setPage01('none');
            setPage02('none');
            setPage03('none');
            setPage04('block');
        }
        if(params == 4){
            setPage01('none');
            setPage02('none');
            setPage03('none');
            setPage04('none');
            setPage05('block');
            
        }

        if(params == 5){
            setPage01('none');
            setPage02('none');
            setPage03('none');
            setPage04('none');
            setPage05('block');
     
        }

        if(params == 6){
            setPage01('none');
            setPage02('none');
            setPage03('none');
            setPage04('none');
            setPage05('none');
            setPage06('block');
        }
        if(params == 7){
            setPage01('none');
            setPage02('none');
            setPage03('none');
            setPage04('none');
            setPage05('none');
            setPage06('none');
            setPage07('block');
        }

    }

    async function finishMaster(params) {


       await updateDoc(doc(db, 'clientes', `${type}`), {

        nome: nome,
        cidade: cidade,
        idade: idade,
        telefone: telefone,
        precoPorHora: precoHr,
        aceitaCredito: credito,
        aceitaPix: pix,
        aceitaDinheiro: dinheiro,
        ligacao: ligacao,
        whatsApp: whatsapp,
        videoChamada: videochamada,
        branca: branca,
        latina: latina,
        mulata: mulata,
        negra: negra,
        oriental: oriental,
        loira: loira,
        morena: morena,
        ruiva: ruiva,
        alta: alta,
        mignon: mignon,
        gordinha: gordinha,
        magra: magra,
        silicone: silicone,
        natural: natural,
        peluda: peluda,
        depilada: depilada,
        beijo: beijo,
        duplas: duplas,
        ejaculacaoCorpo: ejaculacaoCorpo,
        facial: facial,
        fantasias: fantasias,
        massagem: massagem,
        namoradinha: namoradinha,
        oralAteFinal: oralAteFinal,
        oralCamisinha: oralCamisinha,
        oralSemCamisinha: oralSemCamisinha,
        pse: pse,
        sexoAnal: sexoAnal,
        beijoNegro: beijoNegro,
        chuvaDourada: chuvaDourada,
        chuvaNegra: chuvaNegra,
        fetichismo: fetichismo,
        gargantaProfunda: gargantaProfunda,
        sadoDuro: sadoDuro,
        sadoSuave: sadoSuave,
        squirting: squirting,
        strapOn: strapOn,
        domicilio: domicilio,
        swing: swing,
        despedidas: despedidas,
        festasEventos: festasEventos,
        hotel: hotel,
        jantarRomantico: jantarRomantico,
        viagens: viagens,
        sobre: sobre,
        url01: url01,
        url02: url02,
        url03: url03,
        url04: url04,
        url05: url05,
        url06: url06,
        url07: url07,
        url08: url08,
        url09: url09,
        videoUp: url10,
        ativo: ativo

      });
     
         
          
          setTimeout(() => {
            navigate('/');
          }, 1000);

    }

 




   async function enviarImagensVideo(params) {

            finishMaster();
            
        
    }












    const vidUp = async (params) => {
        if (!params) return;
      

        const imageRef = ref(storage, `clientesMidias/${emaill}/${'videoPerfil'}`);

        uploadBytes(imageRef, params).then((snapshot) => {
            getDownloadURL(snapshot.ref).then((url) => {
                setUrl10(url);
       
            });
        }  );

    };









    const uploadFile = async (params) => {
        if (!params) return;
      

        const imageRef = ref(storage, `clientesMidias/${emaill}/${'foto01'}`);

        uploadBytes(imageRef, params).then((snapshot) => {
            getDownloadURL(snapshot.ref).then((url) => {
                setUrl01(url);
       
            });
        }  );

    };

  
    const uploadFile2 = (params) => {
        if (!params) return;
    

        const imageRef = ref(storage, `clientesMidias/${emaill}/${'foto02'}`);

        uploadBytes(imageRef, params).then((snapshot) => {
            getDownloadURL(snapshot.ref).then((url) => {
                setUrl02(url);
            });
        }  );

    };


    const uploadFile3 = (params) => {
        if (!params) return;
      

        const imageRef = ref(storage, `clientesMidias/${emaill}/${'foto03'}`);

        uploadBytes(imageRef, params).then((snapshot) => {
            getDownloadURL(snapshot.ref).then((url) => {
                setUrl03(url);
            });
        }  );

    };


    const uploadFile4 = (params) => {
        if (!params) return;
     

        const imageRef = ref(storage, `clientesMidias/${emaill}/${'foto04'}`);

        uploadBytes(imageRef, params).then((snapshot) => {
            getDownloadURL(snapshot.ref).then((url) => {
                setUrl04(url);
            });
        }  );

    };

    const uploadFile5 = (params) => {
        if (!params) return;
     

        const imageRef = ref(storage, `clientesMidias/${emaill}/${'foto05'}`);

        uploadBytes(imageRef, params).then((snapshot) => {
            getDownloadURL(snapshot.ref).then((url) => {
                setUrl05(url);
            });
        }  );

    };


    
  
    const uploadFile6 = (params) => {
        if (!params) return;
     

        const imageRef = ref(storage, `clientesMidias/${emaill}/${'foto06'}`);

        uploadBytes(imageRef, params).then((snapshot) => {
            getDownloadURL(snapshot.ref).then((url) => {
                setUrl06(url);
            });
        }  );

    };

    const uploadFile7 = (params) => {
        if (!params) return;
     

        const imageRef = ref(storage, `clientesMidias/${emaill}/${'foto07'}`);

        uploadBytes(imageRef, params).then((snapshot) => {
            getDownloadURL(snapshot.ref).then((url) => {
                setUrl07(url);
            });
        }  );

    };



    const uploadFile8 = (params) => {
        if (!params) return;
     

        const imageRef = ref(storage, `clientesMidias/${emaill}/${'foto08'}`);

        uploadBytes(imageRef, params).then((snapshot) => {
            getDownloadURL(snapshot.ref).then((url) => {
                setUrl08(url);
            });
        }  );

    };



    const uploadFile9 = (params) => {
        if (!params) return;
     

        const imageRef = ref(storage, `clientesMidias/${emaill}/${'foto09'}`);

        uploadBytes(imageRef, params).then((snapshot) => {
            getDownloadURL(snapshot.ref).then((url) => {
                setUrl09(url);
            });
        }  );

    };














  return (
  
<>
<Header/>

  <div class="card card-1" style={{marginTop: '-100px', display: page01}}>
  <div class="card-heading"></div>
  <div class="card-body">
      <h2 class="title">Criar conta</h2>
   
          <div class="input-group">
              <input class="input--style-1" type="text" placeholder="Email" name="Email"
               onChange={(e) => setEmaill(e.target.value)  }
              />
          </div>
          <div class="row row-space">
              <div class="col-2">
                  <div class="input-group">
                      <input class="input--style-1 js-datepicker" type="password" placeholder="Senha" name="Senha"
                             onChange={(e) => setSenha(e.target.value)  }
                      />
                      <i class="zmdi zmdi-calendar-note input-icon js-btn-calendar"></i>
                  </div>
              </div>

          </div>

      

          <div class="row row-space">
              <div class="col-2">
                  <div class="input-group">
                      <input class="input--style-1 js-datepicker" type="password" placeholder="Confirmar senha" name="Confirmar senha"/>
                      <i class="zmdi zmdi-calendar-note input-icon js-btn-calendar"></i>
                
                
                
                  </div>
                  
              </div>
            
          </div>
          <div class="row row-space col-4">
              
       

              <button class="btn button"
           
               onClick={()=> setMaster(1)}
             
               >Cadastrar-se</button>
                {
                    msgs.length > 2 ? 
<a style={{color: 'red', fontSize: '15px', padding: '10px', margin: '10px', marginLeft: '0px', backgroundColor: '#999'}}>{msgs.replace('auth/', '')}</a>          
                    : ''
                }
            
          </div>




      
  
  </div>
</div>
  
  

  
   

   

<div class="card card-1" style={{marginTop: '-100px', display: page02}}>
            <div class="card-heading"></div>
            <div class="card-body">
                <h2 class="title">Dados Pessoais</h2>
           
                    <div class="input-group">
                        <input
                          onChange={(e) => setNome(e.target.value)  }
                        class="input--style-1" type="text" placeholder="Nome" name="name"/>
                    </div>
                    <div class="row row-space">
                        <div class="col-2">
                            <div class="input-group">
                                <input
                                 onChange={(e) => setCidade(e.target.value)  }
                                class="input--style-1 js-datepicker" type="text" placeholder="Cidade" name="birthday"/>
                                <i class="zmdi zmdi-calendar-note input-icon js-btn-calendar"></i>
                            </div>
                        </div>

                    </div>

                    <div class="row row-space">
                        <div class="col-2">
                            <div class="input-group">
                                <input
                                 onChange={(e) => setIdade(e.target.value)  }
                                class="input--style-1 js-datepicker" type="text" placeholder="Idade" name="birthday"/>
                                <i class="zmdi zmdi-calendar-note input-icon js-btn-calendar"></i>
                            </div>
                        </div>

                    </div>

                    
                    <div class="row row-space">
                        <div class="col-2">
                            <div class="input-group">
                                <input 
                                 onChange={(e) => setTelefone(e.target.value)  }
                                class="input--style-1 js-datepicker" type="text" placeholder="Telefone" name="birthday"/>
                                <i class="zmdi zmdi-calendar-note input-icon js-btn-calendar"></i>
                            </div>
                        </div>
                    </div>




                    <div class="row row-space">
                        <div class="col-2">
                            <div class="input-group">
                                <input 
                                maxlength="297"
                                 onChange={(e) => setSobre(e.target.value)  }
                                class="input--style-1 js-datepicker" type="text" placeholder="Apresentação/Bio" name="birthday"/>
                                <i class="zmdi zmdi-calendar-note input-icon js-btn-calendar"></i>
                            </div>
                        </div>
                    </div>








                    <div class="row row-space col-4">
                        
                 

                        <button class="btn button"
                         type="submit"
                         onClick={()=> setMaster(2)}
                         >Continuar</button>

                      
                      
                    </div>

            
            </div>
        </div>
 




        <div class="card card-1" style={{marginTop: '-100px', display: page03}}>
            <div class="card-heading"></div>
            <div class="card-body">
                <h2 class="title">Preços / Contato</h2>
           
                    <div class="input-group" style={{width: '150px', display: 'flex', flexFlow: 'row nowrap'}}>
                        <a style={{marginTop: '9px', marginRight: '8px', color: '#666'}}>R$:  </a><input
                         onChange={(e) => setPrecoHr(e.target.value)  }
                        class="input--style-1" type="text" placeholder="Preço por hora" name="preço por hora"/>
                    </div>
                    <div class="row row-space">
                        <div class="col-2">
                            <div class="input-group" > <h5 style={{marginTop: '12px', marginRight: '15px', color: '#555'}}>Pagamentos:</h5>
                         <div style={{ paddingLeft: '10px', paddingRight: '10px', display: 'flex', alignItems: 'center', borderRadius: '10px', border: '2px solid #777', margin: '5px' }}>Cartão de crédito 
                         
                         <div class="checkbox-wrapper-2" style={{paddingTop: '5px', marginLeft: '10px'}}>
  <input type="checkbox" class="sc-gJwTLC ikxBAC"
   onChange={(e) => setCredito(e.target.checked)  }
  />
</div>
                         
                         
                         
                         </div>

<div style={{ paddingLeft: '10px', paddingRight: '10px', display: 'flex', alignItems: 'center', borderRadius: '10px', border: '2px solid #777', margin: '5px', marginLeft: '2px' }}>Pix 

<div class="checkbox-wrapper-2" style={{paddingTop: '5px', marginLeft: '10px'}}>
  <input type="checkbox" class="sc-gJwTLC ikxBAC"
   onChange={(e) => setPix(e.target.checked)  }
  />
</div>


</div>
<div style={{ paddingLeft: '10px', paddingRight: '10px', display: 'flex', alignItems: 'center', borderRadius: '10px', border: '2px solid #777', margin: '5px', marginLeft: '2px' }}>Dinheiro 

<div class="checkbox-wrapper-2" style={{paddingTop: '5px', marginLeft: '10px'}}>
  <input type="checkbox" class="sc-gJwTLC ikxBAC"
   onChange={(e) => setDinheiro(e.target.checked)  }
  />
</div>



</div>
                           
                                <i class="zmdi zmdi-calendar-note input-icon js-btn-calendar"></i>
                            </div>
                        </div>

                    </div>
                    <div class="row row-space">
                        <div class="col-2">
                            <div class="input-group" > <h5 style={{marginTop: '12px', marginRight: '15px', color: '#555'}}>Contato:</h5>
                         <div style={{ paddingLeft: '10px', paddingRight: '10px', display: 'flex', alignItems: 'center', borderRadius: '10px', border: '2px solid #777', margin: '5px' }}>Ligação 
                         
                         <div class="checkbox-wrapper-2" style={{paddingTop: '5px', marginLeft: '10px'}}>
  <input type="checkbox" class="sc-gJwTLC ikxBAC"
   onChange={(e) => setLigacao(e.target.checked)  }
  />
</div>
                         
                         
                         </div>

<div style={{ paddingLeft: '10px', paddingRight: '10px', display: 'flex', alignItems: 'center', borderRadius: '10px', border: '2px solid #777', margin: '5px', marginLeft: '2px' }}>Whatsapp 

<div class="checkbox-wrapper-2" style={{paddingTop: '5px', marginLeft: '10px'}}>
  <input type="checkbox" class="sc-gJwTLC ikxBAC"
   onChange={(e) => setWhatsapp(e.target.checked)  }
  />
</div>



</div>
<div style={{ paddingLeft: '10px', paddingRight: '10px', display: 'flex', alignItems: 'center', borderRadius: '10px', border: '2px solid #777', margin: '5px', marginLeft: '2px' }}>Videochamada 

<div class="checkbox-wrapper-2" style={{paddingTop: '5px', marginLeft: '10px'}}>
  <input type="checkbox" class="sc-gJwTLC ikxBAC"
   onChange={(e) => setVideochamada(e.target.checked)  }
  />
</div>


</div>
                           
                                <i class="zmdi zmdi-calendar-note input-icon js-btn-calendar"></i>
                            </div>
                        </div>

                    </div>

              











                    <div class="row row-space col-4">
                        
                 

                        <button class="btn button"
                         type="submit"
                         onClick={()=> setMaster(3)}
                         >Continuar</button>

                      
                      
                    </div>

            
            </div>
        </div>
 

 





        <div class="card card-1" style={{marginTop: '-100px', display: page04}}>
            <div class="card-heading"></div>
            <div class="card-body">
                <h2 class="title">Aparência</h2>
           
                 
                    <div class="row row-space">
                        <div class="col-7">
                            <div class="input-group" > <h5 style={{marginTop: '12px', marginRight: '15px', color: '#555'}}>Etnia:</h5>
                         <div style={{ paddingLeft: '10px', paddingRight: '10px', display: 'flex', alignItems: 'center', borderRadius: '10px', border: '2px solid #777', margin: '5px' }}>Branca 
                         
                         <div class="checkbox-wrapper-2" style={{paddingTop: '5px', marginLeft: '10px'}}>
  <input type="checkbox" class="sc-gJwTLC ikxBAC"
   onChange={(e) => setBranca(e.target.checked)  }
  />
</div>
                         
                         
                         </div>

<div style={{ paddingLeft: '10px', paddingRight: '10px', display: 'flex', alignItems: 'center', borderRadius: '10px', border: '2px solid #777', margin: '5px', marginLeft: '2px' }}>Latina 

<div class="checkbox-wrapper-2" style={{paddingTop: '5px', marginLeft: '10px'}}>
  <input type="checkbox" class="sc-gJwTLC ikxBAC"
   onChange={(e) => setLatina(e.target.checked)  }
  />
</div>



</div>
<div style={{ paddingLeft: '10px', paddingRight: '10px', display: 'flex', alignItems: 'center', borderRadius: '10px', border: '2px solid #777', margin: '5px', marginLeft: '2px' }}>Mulata 

<div class="checkbox-wrapper-2" style={{paddingTop: '5px', marginLeft: '10px'}}>
  <input type="checkbox" class="sc-gJwTLC ikxBAC"
   onChange={(e) => setMulata(e.target.checked)  }
  />
</div>



</div>
                           
                           <div style={{ paddingLeft: '10px', paddingRight: '10px', display: 'flex', alignItems: 'center', borderRadius: '10px', border: '2px solid #777', margin: '5px', marginLeft: '2px' }}>Negra 
                           
                           <div class="checkbox-wrapper-2" style={{paddingTop: '5px', marginLeft: '10px'}}>
  <input type="checkbox" class="sc-gJwTLC ikxBAC"
   onChange={(e) => setNegra(e.target.checked)  }
  />
</div>

                           
                           </div>

<div style={{ paddingLeft: '10px', paddingRight: '10px', display: 'flex', alignItems: 'center', borderRadius: '10px', border: '2px solid #777', margin: '5px', marginLeft: '2px' }}>Oriental 

<div class="checkbox-wrapper-2" style={{paddingTop: '5px', marginLeft: '10px'}}>
  <input type="checkbox" class="sc-gJwTLC ikxBAC"
   onChange={(e) => setOriental(e.target.checked)  }
  />
</div>


                           
                           </div>

                                <i class="zmdi zmdi-calendar-note input-icon js-btn-calendar"></i>
                            </div>
                        </div>

                    </div>



                    <div class="row row-space">
                        <div class="col-2">
                            <div class="input-group" > <h5 style={{marginTop: '12px', marginRight: '15px', color: '#555'}}>Cabelo:</h5>
                         <div style={{ paddingLeft: '10px', paddingRight: '10px', display: 'flex', alignItems: 'center', borderRadius: '10px', border: '2px solid #777', margin: '5px' }}>Loira 
                         
                         <div class="checkbox-wrapper-2" style={{paddingTop: '5px', marginLeft: '10px'}}>
  <input type="checkbox" class="sc-gJwTLC ikxBAC"
   onChange={(e) => setLoira(e.target.checked)  }
  />
</div>
                         
                         
                         </div>

<div style={{ paddingLeft: '10px', paddingRight: '10px', display: 'flex', alignItems: 'center', borderRadius: '10px', border: '2px solid #777', margin: '5px', marginLeft: '2px' }}>Morena 

<div class="checkbox-wrapper-2" style={{paddingTop: '5px', marginLeft: '10px'}}>
  <input type="checkbox" class="sc-gJwTLC ikxBAC"
   onChange={(e) => setMorena(e.target.checked)  }
  />
</div>

</div>
<div style={{ paddingLeft: '10px', paddingRight: '10px', display: 'flex', alignItems: 'center', borderRadius: '10px', border: '2px solid #777', margin: '5px', marginLeft: '2px' }}>Ruiva 


<div class="checkbox-wrapper-2" style={{paddingTop: '5px', marginLeft: '10px'}}>
  <input type="checkbox" class="sc-gJwTLC ikxBAC"
   onChange={(e) => setRuiva(e.target.checked)  }
  />
</div>



</div>
                           
                                <i class="zmdi zmdi-calendar-note input-icon js-btn-calendar"></i>
                            </div>
                        </div>

                    </div>




                    <div class="row row-space">
                        <div class="col-2">
                            <div class="input-group" > <h5 style={{marginTop: '12px', marginRight: '15px', color: '#555'}}>Estatura:</h5>
                         <div style={{ paddingLeft: '10px', paddingRight: '10px', display: 'flex', alignItems: 'center', borderRadius: '10px', border: '2px solid #777', margin: '5px' }}>Alta 
                         
                         
                         <div class="checkbox-wrapper-2" style={{paddingTop: '5px', marginLeft: '10px'}}>
  <input type="checkbox" class="sc-gJwTLC ikxBAC"
   onChange={(e) => setAlta(e.target.checked)  }
  />
</div>
                         
                         
                         </div>

<div style={{ paddingLeft: '10px', paddingRight: '10px', display: 'flex', alignItems: 'center', borderRadius: '10px', border: '2px solid #777', margin: '5px', marginLeft: '2px' }}>Mignon 

<div class="checkbox-wrapper-2" style={{paddingTop: '5px', marginLeft: '10px'}}>
  <input type="checkbox" class="sc-gJwTLC ikxBAC"
   onChange={(e) => setMignon(e.target.checked)  }
  />
</div>


</div>

                           
                                <i class="zmdi zmdi-calendar-note input-icon js-btn-calendar"></i>
                            </div>
                        </div>

                    </div>



                    <div class="row row-space">
                        <div class="col-2">
                            <div class="input-group" > <h5 style={{marginTop: '12px', marginRight: '15px', color: '#555'}}>Corpo:</h5>
                         <div style={{ paddingLeft: '10px', paddingRight: '10px', display: 'flex', alignItems: 'center', borderRadius: '10px', border: '2px solid #777', margin: '5px' }}>Gordinha 
                         
                         
                         <div class="checkbox-wrapper-2" style={{paddingTop: '5px', marginLeft: '10px'}}>
  <input type="checkbox" class="sc-gJwTLC ikxBAC"
   onChange={(e) => setGordinha(e.target.checked)  }
  />
</div>
                         
                         
                         
                         
                         </div>

<div style={{ paddingLeft: '10px', paddingRight: '10px', display: 'flex', alignItems: 'center', borderRadius: '10px', border: '2px solid #777', margin: '5px', marginLeft: '2px' }}>Magra 


<div class="checkbox-wrapper-2" style={{paddingTop: '5px', marginLeft: '10px'}}>
  <input type="checkbox" class="sc-gJwTLC ikxBAC"
   onChange={(e) => setMagra(e.target.checked)  }
  />
</div>



</div>

                           
                                <i class="zmdi zmdi-calendar-note input-icon js-btn-calendar"></i>
                            </div>
                        </div>

                    </div>



                    <div class="row row-space">
                        <div class="col-2">
                            <div class="input-group" > <h5 style={{marginTop: '12px', marginRight: '15px', color: '#555'}}>Seios:</h5>
                         <div style={{ paddingLeft: '10px', paddingRight: '10px', display: 'flex', alignItems: 'center', borderRadius: '10px', border: '2px solid #777', margin: '5px' }}>Silicone 
                         
                         
                         <div class="checkbox-wrapper-2" style={{paddingTop: '5px', marginLeft: '10px'}}>
  <input type="checkbox" class="sc-gJwTLC ikxBAC"
   onChange={(e) => setSilicone(e.target.checked)  }
  />
</div>
                         
                         
                         
                         </div>

<div style={{ paddingLeft: '10px', paddingRight: '10px', display: 'flex', alignItems: 'center', borderRadius: '10px', border: '2px solid #777', margin: '5px', marginLeft: '2px' }}>Natural 



<div class="checkbox-wrapper-2" style={{paddingTop: '5px', marginLeft: '10px'}}>
  <input type="checkbox" class="sc-gJwTLC ikxBAC"
   onChange={(e) => setNatural(e.target.checked)  }
  />
</div>


</div>

                           
                                <i class="zmdi zmdi-calendar-note input-icon js-btn-calendar"></i>
                            </div>
                        </div>

                    </div>


                    <div class="row row-space">
                        <div class="col-2">
                            <div class="input-group" > <h5 style={{marginTop: '12px', marginRight: '15px', color: '#555'}}>Púbis:</h5>
                         <div style={{ paddingLeft: '10px', paddingRight: '10px', display: 'flex', alignItems: 'center', borderRadius: '10px', border: '2px solid #777', margin: '5px' }}>Peludo 
                         
                         <div class="checkbox-wrapper-2" style={{paddingTop: '5px', marginLeft: '10px'}}>
  <input type="checkbox" class="sc-gJwTLC ikxBAC"
   onChange={(e) => setPeluda(e.target.checked)  }
  />
</div>
                         
                         
                         </div>

<div style={{ paddingLeft: '10px', paddingRight: '10px', display: 'flex', alignItems: 'center', borderRadius: '10px', border: '2px solid #777', margin: '5px', marginLeft: '2px' }}>Depilado 

<div class="checkbox-wrapper-2" style={{paddingTop: '5px', marginLeft: '10px'}}>
  <input type="checkbox" class="sc-gJwTLC ikxBAC"
   onChange={(e) => setDepilada(e.target.checked)  }
  />
</div>


</div>

                           
                                <i class="zmdi zmdi-calendar-note input-icon js-btn-calendar"></i>
                            </div>
                        </div>

                    </div>














                    <div class="row row-space col-4">
                        
                 

                        <button class="btn button"
                         type="submit"
                         onClick={()=> setMaster(4)}
                         >Continuar</button>

                      
                      
                    </div>

            
            </div>
        </div>
 



        <div class="card card-1" style={{marginTop: '-100px', display: page05}}>
            <div class="card-heading"></div>
            <div class="card-body">
                <h2 class="title">Serviços</h2>
           
                 
                    <div class="row row-space">
                        <div class="col-7">
                            <div class="input-group" > <h5 style={{marginTop: '12px', marginRight: '15px', color: '#555'}}>Serviços gerais:</h5>
                         <div style={{ paddingLeft: '10px', paddingRight: '10px', display: 'flex', alignItems: 'center', borderRadius: '10px', border: '2px solid #777', margin: '5px' }}>Beijos na boca
                         
                         <div class="checkbox-wrapper-2" style={{paddingTop: '5px', marginLeft: '10px'}}>
  <input type="checkbox" class="sc-gJwTLC ikxBAC"
   onChange={(e) => setBeijo(e.target.checked)  }
  />
</div>
                         
                         
                         </div>

<div style={{ paddingLeft: '10px', paddingRight: '10px', display: 'flex', alignItems: 'center', borderRadius: '10px', border: '2px solid #777', margin: '5px', marginLeft: '2px' }}>Duplas 

<div class="checkbox-wrapper-2" style={{paddingTop: '5px', marginLeft: '10px'}}>
  <input type="checkbox" class="sc-gJwTLC ikxBAC"
   onChange={(e) => setDuplas(e.target.checked)  }
  />
</div>

</div>
<div style={{ paddingLeft: '10px', paddingRight: '10px', display: 'flex', alignItems: 'center', borderRadius: '10px', border: '2px solid #777', margin: '5px', marginLeft: '2px' }}>Ejaculação corpo 


<div class="checkbox-wrapper-2" style={{paddingTop: '5px', marginLeft: '10px'}}>
  <input type="checkbox" class="sc-gJwTLC ikxBAC"
   onChange={(e) => setEjaculacaoCorpo(e.target.checked)  }
  />
</div>


</div>
                           
                           <div style={{ paddingLeft: '10px', paddingRight: '10px', display: 'flex', alignItems: 'center', borderRadius: '10px', border: '2px solid #777', margin: '5px', marginLeft: '2px' }}>Facial 
                           
                           
                           <div class="checkbox-wrapper-2" style={{paddingTop: '5px', marginLeft: '10px'}}>
  <input type="checkbox" class="sc-gJwTLC ikxBAC"
   onChange={(e) => setFacial(e.target.checked)  }
  />
</div>
                           
                           
                           
                           </div>

<div style={{ paddingLeft: '10px', paddingRight: '10px', display: 'flex', alignItems: 'center', borderRadius: '10px', border: '2px solid #777', margin: '5px', marginLeft: '2px' }}>Fantasias e disfarces


<div class="checkbox-wrapper-2" style={{paddingTop: '5px', marginLeft: '10px'}}>
  <input type="checkbox" class="sc-gJwTLC ikxBAC"
   onChange={(e) => setFantasias(e.target.checked)  }
  />
</div>



</div>

<div style={{ paddingLeft: '10px', paddingRight: '10px', display: 'flex', alignItems: 'center', borderRadius: '10px', border: '2px solid #777', margin: '5px', marginLeft: '2px' }}>Massagem erótica

<div class="checkbox-wrapper-2" style={{paddingTop: '5px', marginLeft: '10px'}}>
  <input type="checkbox" class="sc-gJwTLC ikxBAC"
   onChange={(e) => setMassagem(e.target.checked)  }
  />
</div>


</div>
<div style={{ paddingLeft: '10px', paddingRight: '10px', display: 'flex', alignItems: 'center', borderRadius: '10px', border: '2px solid #777', margin: '5px', marginLeft: '2px' }}>Namoradinha


<div class="checkbox-wrapper-2" style={{paddingTop: '5px', marginLeft: '10px'}}>
  <input type="checkbox" class="sc-gJwTLC ikxBAC"
   onChange={(e) => setNamoradinha(e.target.checked)  }
  />
</div>


</div>

<div style={{ paddingLeft: '10px', paddingRight: '10px', display: 'flex', alignItems: 'center', borderRadius: '10px', border: '2px solid #777', margin: '5px', marginLeft: '2px' }}>Oral até o final

<div class="checkbox-wrapper-2" style={{paddingTop: '5px', marginLeft: '10px'}}>
  <input type="checkbox" class="sc-gJwTLC ikxBAC"
   onChange={(e) => setOralAteFinal(e.target.checked)  }
  />
</div>


</div>

<div style={{ paddingLeft: '10px', paddingRight: '10px', display: 'flex', alignItems: 'center', borderRadius: '10px', border: '2px solid #777', margin: '5px', marginLeft: '2px' }}>Oral com camisinha


<div class="checkbox-wrapper-2" style={{paddingTop: '5px', marginLeft: '10px'}}>
  <input type="checkbox" class="sc-gJwTLC ikxBAC"
   onChange={(e) => setOralCamisinha(e.target.checked)  }
  />
</div>


</div>

<div style={{ paddingLeft: '10px', paddingRight: '10px', display: 'flex', alignItems: 'center', borderRadius: '10px', border: '2px solid #777', margin: '5px', marginLeft: '2px' }}>Oral sem camisinha

<div class="checkbox-wrapper-2" style={{paddingTop: '5px', marginLeft: '10px'}}>
  <input type="checkbox" class="sc-gJwTLC ikxBAC"
   onChange={(e) => setOralSemCamisinha(e.target.checked)  }
  />
</div>


</div>

<div style={{ paddingLeft: '10px', paddingRight: '10px', display: 'flex', alignItems: 'center', borderRadius: '10px', border: '2px solid #777', margin: '5px', marginLeft: '2px' }}>PSE


<div class="checkbox-wrapper-2" style={{paddingTop: '5px', marginLeft: '10px'}}>
  <input type="checkbox" class="sc-gJwTLC ikxBAC"
   onChange={(e) => setPse(e.target.checked)  }
  />
</div>



</div>

<div style={{ paddingLeft: '10px', paddingRight: '10px', display: 'flex', alignItems: 'center', borderRadius: '10px', border: '2px solid #777', margin: '5px', marginLeft: '2px' }}>Sexo anal

<div class="checkbox-wrapper-2" style={{paddingTop: '5px', marginLeft: '10px'}}>
  <input type="checkbox" class="sc-gJwTLC ikxBAC"
   onChange={(e) => setSexoAnal(e.target.checked)  }
  />
</div>


</div>


                                <i class="zmdi zmdi-calendar-note input-icon js-btn-calendar"></i>
                            </div>
                        </div>

                    </div>



            


          

                    <div class="row row-space">
                        <div class="col-7">
                            <div class="input-group" > <h5 style={{marginTop: '12px', marginRight: '15px', color: '#555'}}>Serviços especiais:</h5>
                         <div style={{ paddingLeft: '10px', paddingRight: '10px', display: 'flex', alignItems: 'center', borderRadius: '10px', border: '2px solid #777', margin: '5px' }}>Beijo negro 
                         
                         
                         <div class="checkbox-wrapper-2" style={{paddingTop: '5px', marginLeft: '10px'}}>
  <input type="checkbox" class="sc-gJwTLC ikxBAC"
   onChange={(e) => setBeijoNegro(e.target.checked)  }
  />
</div>
                         
                         </div>

<div style={{ paddingLeft: '10px', paddingRight: '10px', display: 'flex', alignItems: 'center', borderRadius: '10px', border: '2px solid #777', margin: '5px', marginLeft: '2px' }}>Chuva dourada 
<div class="checkbox-wrapper-2" style={{paddingTop: '5px', marginLeft: '10px'}}>
  <input type="checkbox" class="sc-gJwTLC ikxBAC"
   onChange={(e) => setChuvaDourada(e.target.checked)  }
  />
</div>


</div>
<div style={{ paddingLeft: '10px', paddingRight: '10px', display: 'flex', alignItems: 'center', borderRadius: '10px', border: '2px solid #777', margin: '5px', marginLeft: '2px' }}>Chuva negra 


<div class="checkbox-wrapper-2" style={{paddingTop: '5px', marginLeft: '10px'}}>
  <input type="checkbox" class="sc-gJwTLC ikxBAC"
   onChange={(e) => setChuvaNegra(e.target.checked)  }
  />
</div>



</div>
                           
                           <div style={{ paddingLeft: '10px', paddingRight: '10px', display: 'flex', alignItems: 'center', borderRadius: '10px', border: '2px solid #777', margin: '5px', marginLeft: '2px' }}>Fetichismo 
                           
                           
                           
                           <div class="checkbox-wrapper-2" style={{paddingTop: '5px', marginLeft: '10px'}}>
  <input type="checkbox" class="sc-gJwTLC ikxBAC"
   onChange={(e) => setFetichismo(e.target.checked)  }
  />
</div>
                           
                           
                           
                           </div>

<div style={{ paddingLeft: '10px', paddingRight: '10px', display: 'flex', alignItems: 'center', borderRadius: '10px', border: '2px solid #777', margin: '5px', marginLeft: '2px' }}>Garganta profunda

<div class="checkbox-wrapper-2" style={{paddingTop: '5px', marginLeft: '10px'}}>
  <input type="checkbox" class="sc-gJwTLC ikxBAC"
   onChange={(e) => setGargantaProfunda(e.target.checked)  }
  />
</div>


</div>

<div style={{ paddingLeft: '10px', paddingRight: '10px', display: 'flex', alignItems: 'center', borderRadius: '10px', border: '2px solid #777', margin: '5px', marginLeft: '2px' }}>Sado duro


<div class="checkbox-wrapper-2" style={{paddingTop: '5px', marginLeft: '10px'}}>
  <input type="checkbox" class="sc-gJwTLC ikxBAC"
   onChange={(e) => setSadoDuro(e.target.checked)  }
  />
</div>


</div>
<div style={{ paddingLeft: '10px', paddingRight: '10px', display: 'flex', alignItems: 'center', borderRadius: '10px', border: '2px solid #777', margin: '5px', marginLeft: '2px' }}>Sado suave

<div class="checkbox-wrapper-2" style={{paddingTop: '5px', marginLeft: '10px'}}>
  <input type="checkbox" class="sc-gJwTLC ikxBAC"
   onChange={(e) => setSadoSuave(e.target.checked)  }
  />
</div>


</div>

<div style={{ paddingLeft: '10px', paddingRight: '10px', display: 'flex', alignItems: 'center', borderRadius: '10px', border: '2px solid #777', margin: '5px', marginLeft: '2px' }}>Squirting

<div class="checkbox-wrapper-2" style={{paddingTop: '5px', marginLeft: '10px'}}>
  <input type="checkbox" class="sc-gJwTLC ikxBAC"
   onChange={(e) => setSquirting(e.target.checked)  }
  />
</div>

</div>

<div style={{ paddingLeft: '10px', paddingRight: '10px', display: 'flex', alignItems: 'center', borderRadius: '10px', border: '2px solid #777', margin: '5px', marginLeft: '2px' }}>Strap on



<div class="checkbox-wrapper-2" style={{paddingTop: '5px', marginLeft: '10px'}}>
  <input type="checkbox" class="sc-gJwTLC ikxBAC"
   onChange={(e) => setStrapOn(e.target.checked)  }
  />
</div>


</div>




                                <i class="zmdi zmdi-calendar-note input-icon js-btn-calendar"></i>
                            </div>
                        </div>

                    </div>




                    <div class="row row-space">
                        <div class="col-7">
                            <div class="input-group" > <h5 style={{marginTop: '12px', marginRight: '15px', color: '#555'}}>Lugar:</h5>
                         <div style={{ paddingLeft: '10px', paddingRight: '10px', display: 'flex', alignItems: 'center', borderRadius: '10px', border: '2px solid #777', margin: '5px' }}>A domicilio
                         
                         
                         <div class="checkbox-wrapper-2" style={{paddingTop: '5px', marginLeft: '10px'}}>
  <input type="checkbox" class="sc-gJwTLC ikxBAC"
   onChange={(e) => setDomicilio(e.target.checked)  }
  />
</div>
                         
                         </div>

<div style={{ paddingLeft: '10px', paddingRight: '10px', display: 'flex', alignItems: 'center', borderRadius: '10px', border: '2px solid #777', margin: '5px', marginLeft: '2px' }}>Clube de Swing 

<div class="checkbox-wrapper-2" style={{paddingTop: '5px', marginLeft: '10px'}}>
  <input type="checkbox" class="sc-gJwTLC ikxBAC"
   onChange={(e) => setSwing(e.target.checked)  }
  />
</div>



</div>
<div style={{ paddingLeft: '10px', paddingRight: '10px', display: 'flex', alignItems: 'center', borderRadius: '10px', border: '2px solid #777', margin: '5px', marginLeft: '2px' }}>Com local 



<div class="checkbox-wrapper-2" style={{paddingTop: '5px', marginLeft: '10px'}}>
  <input type="checkbox" class="sc-gJwTLC ikxBAC"
   onChange={(e) => setComLocal(e.target.checked)  }
  />
</div>



</div>
                           
                           <div style={{ paddingLeft: '10px', paddingRight: '10px', display: 'flex', alignItems: 'center', borderRadius: '10px', border: '2px solid #777', margin: '5px', marginLeft: '2px' }}>Despedidas de solteiro 
                           
                           <div class="checkbox-wrapper-2" style={{paddingTop: '5px', marginLeft: '10px'}}>
  <input type="checkbox" class="sc-gJwTLC ikxBAC"
   onChange={(e) => setDespedidas(e.target.checked)  }
  />
</div>
                           
                           
                           
                           </div>

<div style={{ paddingLeft: '10px', paddingRight: '10px', display: 'flex', alignItems: 'center', borderRadius: '10px', border: '2px solid #777', margin: '5px', marginLeft: '2px' }}>Festas e eventos

<div class="checkbox-wrapper-2" style={{paddingTop: '5px', marginLeft: '10px'}}>
  <input type="checkbox" class="sc-gJwTLC ikxBAC"
   onChange={(e) => setFestasEventos(e.target.checked)  }
  />
</div>


</div>

<div style={{ paddingLeft: '10px', paddingRight: '10px', display: 'flex', alignItems: 'center', borderRadius: '10px', border: '2px solid #777', margin: '5px', marginLeft: '2px' }}>Hotel

<div class="checkbox-wrapper-2" style={{paddingTop: '5px', marginLeft: '10px'}}>
  <input type="checkbox" class="sc-gJwTLC ikxBAC"
   onChange={(e) => setHotel(e.target.checked)  }
  />
</div>




</div>
<div style={{ paddingLeft: '10px', paddingRight: '10px', display: 'flex', alignItems: 'center', borderRadius: '10px', border: '2px solid #777', margin: '5px', marginLeft: '2px' }}>Jantar romântico


<div class="checkbox-wrapper-2" style={{paddingTop: '5px', marginLeft: '10px'}}>
  <input type="checkbox" class="sc-gJwTLC ikxBAC"
   onChange={(e) => setJantarRomantico(e.target.checked)  }
  />
</div>



</div>

<div style={{ paddingLeft: '10px', paddingRight: '10px', display: 'flex', alignItems: 'center', borderRadius: '10px', border: '2px solid #777', margin: '5px', marginLeft: '2px' }}>Viagens


<div class="checkbox-wrapper-2" style={{paddingTop: '5px', marginLeft: '10px'}}>
  <input type="checkbox" class="sc-gJwTLC ikxBAC"
   onChange={(e) => setViagens(e.target.checked)  }
  />
</div>





</div>





                                <i class="zmdi zmdi-calendar-note input-icon js-btn-calendar"></i>
                            </div>
                        </div>

                    </div>









                    <div class="row row-space col-4">
                        
                 

                        <button class="btn button"
                         type="submit"
                         onClick={()=> setMaster(6)}
                         >Continuar</button>

                      
                      
                    </div>

            
            </div>
        </div>
 




        <div class="card card-1" style={{marginTop: '-100px', display: page06}}>
            <div class="card-heading"></div>
            <div class="card-body">
                <h2 class="title">Fotos / vídeo </h2>
                <a style={{color: '#ff5e5e'}}>*Não esolha para o perfil fotos com a largua maior que altura.</a> 
                    <div class="input-group" style={{width: '400px'}} >
                  <a>Foto 1 (Perfil)</a>  <input
   type="file"
   onChange={(event) => {
    setImageUpload(event.target.files[0]);
    uploadFile(event.target.files[0]);
   }}
   />
                    </div>

{
    url01.length > 2 ?
    
    <div class="input-group" style={{width: '400px'}} >
    <a>Foto 2</a>  <input
type="file"
onChange={(event) => {
uploadFile2(event.target.files[0]);
}}
/>
      </div>

: ''
}

                   
            
{
  url02.length > 2 ?
  <div class="input-group" style={{width: '400px'}} >
                  <a>Foto 3</a>  <input
   type="file"
   onChange={(event) => {
    uploadFile3(event.target.files[0]);
   }}
   />
                    </div>

  : ''
}
                    
                  


{
    url03.length > 2 ?

    <div class="input-group" style={{width: '400px'}} >
    <a>Foto 4</a>  <input
type="file"
onChange={(event) => {
uploadFile4(event.target.files[0]);
}}
/>
      </div>

    : ''
}

                  

{
    url04.length > 2 ?

    <div class="input-group" style={{width: '400px'}} >
    <a>Foto 5</a>  <input
type="file"
onChange={(event) => {
uploadFile5(event.target.files[0]);
}}
/>
      </div>

    : ''
}



{
    url05.length > 2 ?

    <div class="input-group" style={{width: '400px'}} >
    <a>Foto 6</a>  <input
type="file"
onChange={(event) => {
uploadFile6(event.target.files[0]);
}}
/>
      </div>

    : ''
}





{
    url06.length > 2 ?

    <div class="input-group" style={{width: '400px'}} >
    <a>Foto 7</a>  <input
type="file"
onChange={(event) => {
uploadFile7(event.target.files[0]);
}}
/>
      </div>

    : ''
}



{
    url07.length > 2 ?

    <div class="input-group" style={{width: '400px'}} >
    <a>Foto 8</a>  <input
type="file"
onChange={(event) => {
uploadFile8(event.target.files[0]);
}}
/>
      </div>

    : ''
}


{
    url08.length > 2 ?

    <div class="input-group" style={{width: '400px'}} >
    <a>Foto 9</a>  <input
type="file"
onChange={(event) => {
uploadFile9(event.target.files[0]);
}}
/>
      </div>

    : ''
}


{
    2 == 2 ?

    <div class="input-group" style={{width: '400px'}} >
    <a>Vídeo</a>  <input
type="file"
onChange={(event) => {
vidUp(event.target.files[0]);
}}
/>
      </div>

    : ''
}



                 







                    <div class="row row-space col-4">
                        
                 
        {url01.length > 2 ?
         <button class="btn button"
         type="submit"
         onClick={()=> finishMaster()}
         >Continuar</button> 
    :
    <button disabled class="btn button"
    type="submit"
   
    >Continuar</button>

    }
                      
                      
                    </div>

            
            </div>
        </div>


  

        </>
  );
}

export default AtualizarExtra;







