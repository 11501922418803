import { useState, useEffect } from 'react';
import { onAuthStateChanged } from 'firebase/auth';
import Header from './Header';
import { db, firebaseAuth, storage } from '../utils/firebase-config';
import { useNavigate } from 'react-router-dom';
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import qr1 from "../assets/qr1.png";
import qr2 from "../assets/qr2.png";
import qr3 from "../assets/qr3.png";
import qr4 from "../assets/qr4.png";
import axios from 'axios';
import CopyToClipboard from 'react-copy-to-clipboard';
import { doc, getDoc, updateDoc } from 'firebase/firestore';

function ComprarVip() {
  const navigate = useNavigate();
  const [current, setCurrent] = useState('');
  const [copied, setCopied] = useState(false);
  const [imageUpload, setImageUpload] = useState();
  const [qrcodeimg, setQrcodeimg] = useState('');
  const [qrtext, setQrText] = useState('');

  useEffect(() => {
    onAuthStateChanged(firebaseAuth, (currentUser) => {
      if (currentUser) {
        setCurrent(currentUser.email);
        console.log('current ', current);
      }
    });
  }, []);

  const uploadFile = () => {
    if (!imageUpload) return;

    const imageRef = ref(storage, `clientesMidias/${current}/videoVerificacao`);

    uploadBytes(imageRef, imageUpload).then((snapshot) => {
      getDownloadURL(snapshot.ref).then((url) => {
        alert('Video enviado com sucesso! Aguarde aprovação da nossa equipe.');
      });
    });
  };

  var headers = {
    'Content-Type': 'application/json;charset=UTF-8',
    "Access-Control-Allow-Origin": "*",
  }

  function addDays(date, days) {
    const result = new Date(date);
    result.setDate(result.getDate() + days);
    return result.toISOString();
  }

  function verificacao(params) {
    var dadosinter = setInterval(async () => {


      const res = await axios.get('https://studio-api-sable.vercel.app/api/v1/api_pix/' + params.id);

      if (res.data.data.payment_response.code == "20000" && res.data.data.payment_response.message == "SUCESSO") {
        const classRef = doc(db, "clientes", firebaseAuth.currentUser.email);


        await updateDoc(classRef, {
          tipoPlano: 'vip',
          subidasSemana: 40,
        });
        const classRef2 = doc(db, "clientes", firebaseAuth.currentUser.email);
        const classSnap = await getDoc(classRef2);
        const transfer = classSnap.data();

        if (transfer && transfer.tipoPlano === 'vip') {
          clearInterval(dadosinter);
          const today = new Date();
          const dateAfter30Days = addDays(today, 30);
          await updateDoc(classRef, {
            expiracaoDaConta: dateAfter30Days,
            expiracaoPlano: dateAfter30Days
          });
          alert('Compra Finalizada!');
          navigate('/');
        }
      }
    }, 20000);
  }

  async function getDatas(params) {
    const classRef = doc(db, "clientes", firebaseAuth.currentUser.email);
    const classSnap = await getDoc(classRef);
    const transfer = classSnap.data();
    const data = {
      nome: transfer.nome,
      email: firebaseAuth.currentUser.email,
      valor: 10990,
      nomeItem: 'vip',
    }

    const res = await axios.post('https://studio-api-sable.vercel.app/api/v1/api_pix', data);
    setQrcodeimg(res.data.data.qr_codes[0].links[0].href);
    setQrText(res.data.data.qr_codes[0].text);
    verificacao(res.data.data);
  }

  const handleCopy = () => {
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 5000);
  };

  return (
    <>
      <Header />
      <div className="demo10" style={{ height: '100%' }}>
        <div className="container">
          <h4 className="py-4 text-center"
            style={{ color: '#dcc780', fontSize: '30px', fontWeight: '800' }}
          >Pagamento por Pix</h4>
          <div className="row" style={{ justifyContent: 'space-evenly' }}>
            <div className="col-md-8 col-sm-6">
              <div className="pricingTable20">
                <div className="pricingTable-header">
                  <h3 className="heading">Pacote Vip</h3>
                </div>
                <div className="pricing-content">
                  <ul>
                    <li style={{ paddingBottom: '50px', fontWeight: '200' }}>
                      Aproxime seu celular para efetuar o pagamento, ou pague pelo código <br></br>
                      <span style={{ fontWeight: '600', textDecoration: 'underline', cursor: 'pointer' }}
                        onClick={() => getDatas()}
                      >{`Pagar com PIX`}</span>
                      <br /> <br />
                      {qrcodeimg.length > 2 &&
                        <img src={qrcodeimg} className='reponsiveQr' onClick={() => getDatas()} />
                      }
                    </li>
                    <div style={{ width: '100%' }}>
                      <br></br>
                      {qrcodeimg.length > 2 &&
                        <>
                          <h5 style={{ fontWeight: 600 }}>Código:</h5>
                          <br></br>
                          {copied && <p style={{ color: '#dcc780', textAlign: 'center', border: '1px solid #dcc780', padding: '5px', maxWidth: '200px', margin: 'auto', marginBottom: '20px' }}>Código copiado!</p>}

                          <CopyToClipboard text={qrtext} onCopy={handleCopy}>
                            <a style={{ cursor: 'pointer', textDecoration: 'underline', fontSize: '15px', fontWeight: '600' }}>Copiar Código</a>
                          </CopyToClipboard>
                        </>
                      }
                      <br></br>
                      <br></br>
                    </div>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ComprarVip;
