
import { onAuthStateChanged } from 'firebase/auth';
import Header from './Header';
import { firebaseAuth, storage } from '../utils/firebase-config';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";


function Extra() {

const navigate = useNavigate();
const [current, setCurrent] = useState('');

const [imageUpload, setImageUpload] = useState();




const uploadFile = () => {
  if (!imageUpload) return;

  const imageRef = ref(storage, `clientesMidias/${current}/videoVerificacao`);

  uploadBytes(imageRef, imageUpload).then((snapshot) => {
      getDownloadURL(snapshot.ref).then((url) => {
          alert('Video enviado com sucesso! Aguade aprovação da nossa equipe.');
      });
  }  );

};











  onAuthStateChanged(firebaseAuth, (currentUser) => {
    if (currentUser) {setCurrent(currentUser.email);
    console.log('current ', current);
    };
  });
  







  return (
<>
<Header/>

<div className="demo10">
  <div className="container">

      <div className="row"
      style={{justifyContent: 'space-evenly'}}

      >
      
   
          <div className="col-md-8 col-sm-6"style={{borderRadius: '40px'}} >
              <div className="pricingTable20" style={{borderRadius: '40px'}}>
          
          
                  <div className="pricing-content"style={{borderRadius: '40px'}} >
                      
                      
                  <h4 className="py-4 text-center"
      style={{color: '#dcc780', fontSize: '30px', fontWeight: '600',
    }}
    
   >COMPRAR EXTRA</h4>
                      
                      <ul>
                  
                          <li style={{display: 'flex', alignItems: 'center', justifyContent: 'center', fontWeight: '100', marginBottom: '160px'}}>

                      No pacote extra, você poderá exibir mais 5 fotos e 1 vídeo em seu anúncio.
                          </li>

                      </ul>
                      <button href="#"
                      onClick={()=> uploadFile()}
                      className="btn button" style={{margin: '20px'}}>Comprar Extra</button>
                      <button href="#"
                      onClick={()=> uploadFile()}
                      className="btn button" style={{margin: '20px'}}>Ver Perfil</button>
                  </div>
              </div>
          </div>
      </div>
  </div>
</div>


</>

  
  );
}

export default Extra;
