
import './Login.css';

import { onAuthStateChanged, signInWithEmailAndPassword } from 'firebase/auth';
import { firebaseAuth } from '../utils/firebase-config';
import { useNavigate } from 'react-router-dom';
function Initial() {



  const navigate = useNavigate();




onAuthStateChanged(firebaseAuth, (currentUser) => {
  if (currentUser) navigate("/");
});




  return (
   <div>
<h1>asdasd</h1>
</div>
  );
}

export default Initial;






