import React, { useEffect } from "react";
import { useState } from "react";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { storage } from "../utils/firebase-config";

import { db } from "../utils/firebase-config";
import { setDoc } from "firebase/firestore/lite";
import { doc, updateDoc } from "firebase/firestore";

function GetDate() {
    const [dataD, setDataD] = useState();


useEffect(()=> {
 
}, [])


async function somar(params) {

    var data = new Date();
    console.log(data.getMinutes());
    setDataD(data);
    data.setMinutes(data.getMinutes() + 3);
    console.log(data);
    setDataD(data);

}



async function obter(params) {
    
    var data = new Date();
    var data2 = new Date();

    const datetime1 = new Date(); // data atual
    const datetime2 = dataD; // data com + horas
    const diferencaEmMilissegundos = datetime2.getTime() - datetime1.getTime();
    const diferencaEmMinutos = diferencaEmMilissegundos / (1000 * 60);
    console.log(`A diferença entre ${datetime1} e ${datetime2} é de ${diferencaEmMinutos} minutos.`);
    if(diferencaEmMinutos <= 0){
        alert('Expirado');
    }

}






  async function aaaa(params) {
  
    const docRef = doc(db, "clientes", "murilo123@gmail.com");


    const data = {
      nome: "Alther"
    };
    
    updateDoc(docRef, data)
    .then(docRef => {
        console.log("Value of an Existing Document Field has been updated");
    })
    .catch(error => {
        console.log(error);
    })











    }
    
  return (
    <div >
 <h2>Delete</h2>

 <button style={{backgroundColor: 'red', padding: '10px'}} onClick={obter}>Comprar Hora</button>
 <button style={{backgroundColor: 'red', padding: '10px'}} onClick={somar}>Somar minutos</button>
  </div>
  );
}

export default GetDate;






