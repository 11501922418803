
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth"
import { getStorage } from "firebase/storage";
import { GoogleAuthProvider } from "firebase/auth"
import { collection, getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyBWIJBgU0RpJkSNti98NIQQAS1gq5lN6sg",
  authDomain: "studioacompanhantes.firebaseapp.com",
  projectId: "studioacompanhantes",
  storageBucket: "studioacompanhantes.appspot.com",
  messagingSenderId: "227352437279",
  appId: "1:227352437279:web:fdbebcf7b44c28dd576b2a"
};

const app = initializeApp(firebaseConfig);

export const firebaseAuth = getAuth(app);

export const db = getFirestore(app)

export const storage = getStorage(app)

export const collectionRef = collection(db, "clientes")