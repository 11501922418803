import logo from './logo.svg';
import './App.css';
import Valores from './components/Transfer';
import Header from './components/Header';
import Anunciante from './components/Anunciante';
import AreaAnunciante from './components/AreaAnunciante';
import Excluir from './components/Excluir';
import Home from './components/Home';

import Login from './components/Login';
import Register from './components/Register';
import UploadTest from './components/uploadTest';
//import Work from './components/Work';
import Read from './components/Read';
import Delete from './components/Delete';
import CreateUpdate from './components/CreateUpdate';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Verificacao from './components/Verificacao';
import Subida from './components/Subida';
import Extra from './components/Extra';
import UsarSubida from './components/UsarSubida';
import New from './components/New';
import Atualizar from './components/Atualizar';
import MyComponent from './components/Update';
import GetDate from './components/Update';
import FivePics from './components/fivePics';
import AtualizarExtra from './components/AtualizarExtra';
import Termos from './components/Termos';
import Comprar from './components/Comprar';
import Suporte from './components/Suporte';
import ComprarExtra from './components/ComprarExtra';
import Initial from './components/Initial';
import Redirecionamento from './components/Redirecionamento';
import ComprarVip from './components/ComprarVip';
import ComprarStandard from './components/ComprarStandard';
import ComprarLegacy from './components/ComprarLegacy';
import ApiPix from './components/ApiPix';


function App() {
  return (
    <div>
         <BrowserRouter>
     <Routes>
  
     <Route exact path="/" element={<Home/>}/>
     <Route exact path="/initial" element={<Initial/>}/>
     <Route exact path="/redirecionamento" element={<Redirecionamento/>}/>
     <Route exact path="/atualizar" element={<Atualizar/>}/>
     <Route exact path="/datas" element={<MyComponent/>}/>
     <Route exact path="/comprarvip" element={<ComprarVip/>}/>
     <Route exact path="/comprarstandard" element={<ComprarStandard/>}/>
     <Route exact path="/comprarlegacy" element={<ComprarLegacy/>}/>
     <Route exact path="/comprar" element={<Comprar/>}/>
     <Route exact path="/suporte" element={<Suporte/>}/>
     <Route exact path="/termos" element={<Termos/>}/>
     <Route exact path="/extras" element={<FivePics/>}/>
     <Route exact path="/atualizarextraahiuaa1589spefsou8upop9wegp9wejgç3j2m" element={<AtualizarExtra/>}/>
     <Route exact path="/uploadextra" element={<FivePics/>}/>
     <Route exact path="/anunciante" element={<Anunciante/>}/>
     <Route exact path="/excluir" element={<Excluir/>}/>
     <Route exact path="/register" element={<Register/>}/>     
     <Route exact path="/delete" element={<GetDate/>}/>     
     <Route exact path="/login" element={<Login/>}/>     
     <Route exact path="/extra" element={<Extra/>}/>     
     <Route exact path="/comprarextra" element={<ComprarExtra/>}/>     
     <Route exact path="/usarextra" element={<Subida/>}/>     
     <Route exact path="/usarsubida" element={<UsarSubida/>}/>     
     <Route exact path="/valores" element={<Valores/>}/>
     <Route exact path="/verificacao" element={<Verificacao/>}/>
     <Route exact path="/area-anunciante" element={<AreaAnunciante/>}/>
      </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
