
import { onAuthStateChanged } from 'firebase/auth';
import Header from './Header';
import { db, firebaseAuth, storage } from '../utils/firebase-config';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { doc, getDoc } from 'firebase/firestore';

var runtime = 0;
function Subida() {

const navigate = useNavigate();


const [datas, setDatas] = useState([]);

const queryParameters = new URLSearchParams(window.location.search)
const type = queryParameters.get("email");

const fetchClass = async () => {

  var transfer = [];
  var dadosinter = setInterval( async () => {
  const classRef = doc(db, "clientes", type);
  const classSnap = await getDoc(classRef);
  console.log("Class data:", classSnap.data());
  transfer.push(classSnap.data());
  if(transfer.length > 0){
    clearInterval(dadosinter);
    trySetData(transfer);
  }
}, 1000);


}

function trySetData(params) {
if(params != undefined){
setDatas(params[0]);
}

}



useEffect(() => {
  fetchClass();
}, []);











  return (
<>
<Header/>

<div className="demo10" style={{height: '100%'}}>
  <div className="container">

      <div className="row"
      style={{justifyContent: 'space-evenly'}}

      >
      
   
          <div className="col-md-8 col-sm-6"style={{borderRadius: '40px'}} >
              <div className="pricingTable20" style={{borderRadius: '40px'}}>
          
          
                  <div className="pricing-content"style={{borderRadius: '40px'}} >
                      
                      
                  <h4 className="py-4 text-center"
      style={{color: '#dcc780', fontSize: '30px', fontWeight: '600',
    }}
    
   >PACOTE EXTRA</h4>
                      
                      <ul>
                  
                          <li style={{display: 'flex', alignItems: 'center', justifyContent: 'center', fontWeight: '100', marginBottom: '160px'}}>

                      No pacote extra, você pode exibir mais 5 fotos e 1 vídeo em seu anúncio.
                          </li>

                      </ul>
                      {datas.extras ? 

<button href="#"
                  
className="btn button"
onClick={()=> navigate(`/uploadextra?email=${type}`)}
style={{margin: '20px'}}>Usar extra</button>

                              : 
                              
<button href="#"
                   onClick={()=> navigate('/comprarextra')}
                  className="btn button" style={{margin: '20px'}}>Comprar extra</button>


                      }
                  
                     
                      <button href="#"
                      onClick={()=> navigate('/anunciante')}
                      className="btn button" style={{margin: '20px'}}>Voltar</button>
                  </div>
              </div>
          </div>
      </div>
  </div>
</div>


</>

  
  );
}

export default Subida;
