
import { onAuthStateChanged } from 'firebase/auth';
import Header from './Header';
import { db, firebaseAuth, storage } from '../utils/firebase-config';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { doc, getDoc, updateDoc } from 'firebase/firestore';


function Redirecionamento() {

const navigate = useNavigate();

const [datas, setDatas] = useState([]);

const queryParameters = new URLSearchParams(window.location.search);
const type2 = queryParameters.get("pagamento");
const type = 'joana@joana.com';
console.log('pagamento =>', type2);
















  return (
<>
<Header/>

<div className="demo10">
  <div className="container">

      <div className="row"
      style={{justifyContent: 'space-evenly'}}

      >
      
   
          <div className="col-md-8 col-sm-6"style={{borderRadius: '40px'}} >
              <div className="pricingTable20" style={{borderRadius: '40px'}}>
          
          
                  <div className="pricing-content"style={{borderRadius: '40px'}} >
                      
                      
                  <h4 className="py-4 text-center"
      style={{color: '#dcc780', fontSize: '30px', fontWeight: '600',
    }}
    
   >Redirecionamento</h4>
                      
                      <ul>
                  
                          <li style={{display: 'flex', alignItems: 'center', justifyContent: 'center', fontWeight: '100', marginBottom: '160px'}}>

                      Usar subida para destacar seu perfil. 
                    
                          </li>

                      </ul>
                      {
                        datas.subidasSemana > 0 ?
                        <button href="#" className="btn button" style={{margin: '20px'}}>Usar subida</button>
                        :
                        <button href="#"
                              onClick={()=> navigate('/valores')}
                        className="btn button" style={{margin: '20px'}}>Ver Pacotes</button>

                      }
                     

                      <button href="#"
                    onClick={()=> navigate('/')}
                      className="btn button" style={{margin: '20px'}}>Voltar</button>
                  </div>
              </div>
          </div>
      </div>
  </div>
</div>


</>

  
  );
}

export default Redirecionamento;
