import './Termos.css';
import img11 from '../assets/local_icon.png';
import img22 from '../assets/trash.png';
import img33 from '../assets/local_icon.png';
import imgtrash from '../assets/trash.png';
import imicon from '../assets/local_icon.png';
import Header from './Header';
import { deleteDoc, doc, getDoc, updateDoc } from 'firebase/firestore';
import { db } from '../utils/firebase-config';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';



var runtime = 0;
function Termos() {


const navigate = useNavigate();









  return (
    <>
  <Header/>


  <h4 className="py-4 text-center"
      style={{color: '#dcc780', fontSize: '30px', fontWeight: '800', marginTop: '125px'}}
    
   >Termos Anúncio</h4>
    

      <div  >
      <div style={{marginBottom: "50px", paddingLeft: '10%', paddingRight: '10%', color: '#666', fontSize: '20px'}}>

    
    <a style={{}}>

    TERMOS E CONDIÇÕES GERAIS DE USO E SERVIÇOS

Este documento (“Termos e Condições Gerais de Uso e Serviços” ou “Termos”) apresenta as condições de uso da Plataforma disponibilizada pela www.studioacompanhante.com  a qual oferece cessão de espaço para anúncios publicitários. Nela, os ANUNCIANTES expõem o seu anúncio e adicionam as informações que considerem pertinentes acerca do conteúdo anunciado, a fim de que os VISITANTES possam efetuar buscas na PLATAFORMA de modo a localizar os anúncios que se adequem ao perfil desejado.

Abaixo esclarecemos alguns pontos que julgamos importantes. Caso persista alguma dúvida acerca de quaisquer pontos discutidos ou não neste documento, por favor, não hesite em contatar-nos pelo endereço : suporte_studioacompanhante@outlook.com 



ADESÃO:
 Este instrumento regula as condições de uso dos serviços da PLATAFORMA sendo um contrato entre os USUÁRIOS e ANUNCIANTES a www.studioacompanhante.com . A utilização dos serviços oferecidos através da PLATAFORMA indica expressamente que você concorda com todos os termos e condições contidos neste instrumento e com as disposições legais aplicáveis a espécie.

VOCÊ ENTENDE E CONCORDA QUE A www.studioacompanhante.com  CONSIDERARÁ O USO DOS SERVIÇOS DISPOSTOS COMO ACEITAÇÃO DESTES TERMOS E TODAS AS DEMAIS DISPOSIÇÕES LEGAIS PERTINENTES À ESPÉCIE.



AO ACEITAR OS TERMOS DO PRESENTE INSTRUMENTO, O USUÁRIO E ANUNCIANTE  AUTORIZA EXPRESSAMENTE O TRATAMENTO DE SEUS DADOS, A FIM DE GARANTIR A MANUTENÇÃO E O BOM DESEMPENHO DAS FUNCIONALIDADES DA PLATAFORMA.

O USUÁRIO E ANUNCIANTE, NESTE ATO, MANIFESTA O SEU COMPLETO CONSENTIMENTO PARA O COMPARTILHAMENTO DOS DADOS COLETADOS E TRATADOS PELA www.studioacompanhante.com , NOS TERMOS DESTE INSTRUMENTO, COM OUTRAS EMPRESAS QUE FAÇAM PARTE DE SEU GRUPO ECONÔMICO, OU SEJAM SUAS PRESTADORAS DE SERVIÇO.
Caso você NÃO CONCORDE com as disposições previstas neste instrumento, NÃO acesse, visualize, baixe ou utilize de qualquer forma nenhuma página, conteúdo, informação ou serviço da www.studioacompanhante.com


CONDIÇÕES GERAIS DE USO:
www.studioacompanhante.com  apenas é uma PLATAFORMA de cessão de espaço publicitário, sendo a sua responsabilidade restrita tão somente ao funcionamento correto da PLATAFORMA e de suas funcionalidades, conforme este instrumento, não detendo a www.studiacompanhante.com nenhuma responsabilidade sobre:  eventuais negociações realizadas entre os USUÁRIOS E ANUNCIANTES, a verificação se o ANUNCIANTE realmente detém a qualificação informada em seu anúncio;  a qualidade dos anúncios;  a efetivação de qualquer pagamento acertado entre os USUÁRIOS E ANUNCIANTES;  por eventuais danos decorrentes de eventos de terceiros, englobando, entre outros, ataque hacker e softwares maliciosos; e  qualquer outro ato ou fato decorrente da conduta dos USUÁRIOS E ANUNCIANTES. 



A www.studioacompanhante.com  possibilita que os VISITANTES e os ANUNCIANTES contatem-se de forma direta, sem qualquer intervenção, seja na negociação ou na efetivação do que fora negociado, não sendo a www.studiacompanhante.com intermediadora ou fornecedora de quaisquer dos serviços ou produtos anunciados na PLATAFORMA, ou empregador/representante/agente de qualquer dos ANUNCIANTES nesta cadastrado.

A www.studioacompanhante.com não detém nenhuma relação com os VISITANTES OU USUÁRIOS , não sendo possível imputar à www.studioacompanhante.com a responsabilidade por qualquer dano eventualmente causado aos ANUNCIANTES ou a terceiros, por atos oriundos dos VISITANTES OU USUARIOS através da PLATAFORMA   www.studioacompanhante.com

A www.studioacompanhante.com não intermedia qualquer negociação que venha a ser realizada entre USUÁRIOS e ANUNCIANTES, ficando somente a cargo destes o acerto das condições do negócio que efetivarem, tais como o valor, a qualidade, a forma, o prazo e outros pontos que julgarem necessários. 



 A www.studioacompanhante.com por não figurar como parte nas transações que eventualmente sejam firmadas entre os USUÁRIOS E/OU ANUNCIANTES, a www.studioacompanhante também não pode obrigar os USUÁRIOS E/ OU ANUNCIANTES a honrarem com as possíveis obrigações assumidas no momento da realização das negociações.

Em nenhuma hipótese a www.studioacompanhante.com será responsabilizada por quaisquer danos suportados pelos USUÁRIOS E/OU ANUNCIANTES por eventual indisponibilidade temporária da PLATAFORMA.


Caso um cadastro seja considerado suspeito de conter dados errôneos ou inverídicos, a www.studioacompanhante.com  se reserva ao direito de suspender, temporária ou definitivamente, sem necessidade de aviso prévio, a ANUNCIANTE responsável pelo cadastro. No caso de suspensão, não assistirá a ANUNCIANTE direito a qualquer tipo de indenização ou ressarcimento por perdas e danos, lucros cessantes ou ainda danos morais. 


A ANUNCIANTE compromete-se a notificar a www.studioacompanhante.com  imediatamente, por meio dos canais de contato mantidos pela www.studioacompanhante.com  na PLATAFORMA, a respeito de qualquer uso não autorizado de sua conta. A ANUNCIANTE será o único responsável pelas operações efetuadas em sua conta, uma vez que o acesso só será possível mediante a utilização de senha de seu exclusivo conhecimento. 


O apelido que a ANUNCIANTE utiliza na PLATAFORMA não poderá guardar semelhança com o nome STUDIO ACOMPAHANTE, tampouco poderá ser utilizado qualquer apelido que insinue ou sugira que o vincule à www.studioacompanhante.com  / STUDIO ACOMPANHANTE.

 Ao seu exclusivo critério a www.studioacompanhante.com poderá excluir, inabilitar, criar limites no uso do serviço, suspender, bloquear, por tempo indeterminado, sem aviso prévio ou contrapartida indenizatória, cadastros de ANUNCIANTES que sejam considerados ofensivos, que infrinjam os termos deste instrumento ou a legislação em vigor. 

A www.studioacompanhante.com se reserva o direito de não permitir novo cadastro de ANUNCIANTES que já tenham sido cancelados, inabilitados, bloqueados, excluídos ou suspensos da PLATAFORMA. Não se permitirá, ainda, a criação de novos cadastros por pessoas cujos cadastros originais tenham sido cancelados, bloqueados, inabilitados, excluídos ou suspensos por infrações às políticas da PLATAFORMA ou à legislação vigente. 

A www.studioacompanhante.com se reserva o direito de, unilateralmente, sem prévio aviso, anuência ou contrapartida indenizatória, recusar qualquer solicitação de cadastro de uma ANUNCIANTE na PLATAFORMA, bem como cancelar, inabilitar, bloquear, excluir ou suspender o uso de um cadastro previamente aceito. 

Ao concordar com o presente instrumento, a ANUNCIANTE declara estar ciente de que é o único responsável pelo seu cadastro, sendo certo que qualquer prejuízo causado pela inserção de informações desatualizadas, inexatas ou inverídicas, não poderão ser imputados à www.studioacompanhante.com 


A www.studioacompanhante.com poderá a qualquer tempo, sem necessidade de aviso prévio, ou contrapartida indenizatória, editar e/ou excluir as funcionalidades existentes, bem como incluir novas funcionalidades à PLATAFORMA.

ANUNCIANTE, poderá ser removido (a), definitiva ou temporariamente da listagem de sua cidade ou de outro filtro de pesquisa que resultaria em seu anúncio, ainda que possua assinatura em vigor ou planos ativos, caso forem identificado que seja menor de idade.


O uso da plataforma  www.studioacompanhante.com é exclusivo para maiores de 18 anos para ANUNCIANTES E USUÁRIOS. 

  A PLATAFORMA, ainda poderá instituir regras de restrinjam o acesso a determinadas funcionalidades, mediante critérios de sua própria conveniência comercial.

Fica resguardado a PLATAFORMA o direito de restringir as mídias publicadas por uma ANUNCIANTE, parcial ou totalmente, cuja visualização poderá ser restrita aos usuários VISITANTES, de acordo com critérios estabelecidos pela PLATAFORMA.


 O USUÁRIO reconhece expressamente que, através deste instrumento, recebe da plataforma  www.studioacompanhante.com  a outorga de uma licença de uso da PLATAFORMA, que é intransferível, sendo vedado o sublicenciamento, para uso em território nacional ou estrangeiro, pelo tempo em que perdurar a adesão a este termo, restando vedado o uso da PLATAFORMA em desacordo com o previsto neste instrumento. 

Os USUÁRIOS e ANUNCIANTES não devem utilizar a www.studioacompanhante.com    para quaisquer fins ou meios ilegais, difamatórios, discriminatórios, abusivos, ofensivos, agressivos, injuriosos, vexatórios, enganosos, caluniosos, violentos, vulgares, ou de assédio, ameaça ou uso de falsa identidade, ou seja, qualquer uso escuso que possa prejudicar a www.studioacompanhante.com  , outros USUÁRIOS , ANUNCIANTES ou terceiros.


Em nenhuma hipótese o USUÁRIO ou ANUNCIANTE  imputará qualquer espécie de responsabilização à www.studioacompanhante.com por informações inverídicas inseridas por outros ANUNCIANTES  na PLATAFORMA, devendo, ainda, informar à www.studioacompanhante.com sobre quaisquer informações não verídicas que identifique na PLATAFORMA.

A anunciante NÃO poderá inserir informações falsas na PLATAFORMA    www.studioacompanhante.com  , caso forem identificados tais atos  seu cadastro poderá  ser excluído, inabilitado, , suspenso , bloqueado, por tempo indeterminado ou permanente, sem aviso prévio ou contrapartida indenizatória.    

Todos os materiais, patentes, marcas, registros, domínios, nomes, privilégios, criações, imagens e todos os direitos conexos e relacionados com a PLATAFORMA www.studioacompanhante.com  , são e permanecerão de única e exclusiva propriedade da www.studioacompanhante.com , concordando os USUÁRIOS e ANUNCIANTES  em não praticar ato ou fato que, por qualquer modo, prejudique os direitos previstos aqui e tampouco reivindicar qualquer direito ou privilégio sobre os mesmos.

A www.studioacompanhante.com  poderá alterar este instrumento a qualquer momento ,por este motivo, recomendamos veementemente que sempre visite esta seção de nosso Site, lendo-a, periodicamente. Mas, para contribuir com o bom relacionamento.










    </a>
      </div>
    </div>
 

 
  </>
  );
}

export default Termos;






