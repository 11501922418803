
import { onAuthStateChanged } from 'firebase/auth';
import Header from './Header';
import { db, firebaseAuth, storage } from '../utils/firebase-config';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { doc, setDoc } from 'firebase/firestore';


function Suporte() {

const navigate = useNavigate();
const [current, setCurrent] = useState('');

const [imageUpload, setImageUpload] = useState();


const queryParameters = new URLSearchParams(window.location.search)
const type = queryParameters.get("email")

const [ mensagem, setMensagem ] = useState('');

const uploadFile = () => {
    setDoc(doc(db, 'suporte', `${type}`), {
      email: type,
      mensagem: mensagem,
    });
    // resetar o campo de mensagem
    navigate('/anunciante');
    alert('Mensagem enviada com sucesso');
};
  return (
<>
<Header/>

<div className="demo10" style={{height: '100%'}}>
  <div className="container">
      <h4 className="py-4 text-center"
      style={{color: '#dcc780', fontSize: '30px', fontWeight: '800'}}
    
   >Suporte</h4>
      <div className="row"
      style={{justifyContent: 'space-evenly'}}

      >
      
   
          <div className="col-md-8 col-sm-6">
              <div className="pricingTable20">
                  <div className="pricingTable-header">
                      <h3 className="heading">Nos Envie Uma mensagem</h3>
                  
                  </div>
                  <div className="pricing-content">
                      <ul>
                          <li style={{paddingBottom : '50px', fontWeight: '200'}}>
                            Nos envie uma mensagem, responderemos no email cadastrado na sua conta. <br></br>
                    
                
                          </li>
                 
                          <li style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>

                            <input 
                            onChange={(e)=> setMensagem(e.target.value)}
                            
                            
                            style={{ height: '100px', padding: '10px', border: 'solid 1px #444'}}
  
                            type='text'></input>
                          </li>

                      </ul>
                      <button href="#"
                      onClick={()=> uploadFile()}
                      className="btn button" style={{margin: '20px'}}>Enviar</button>
                  </div>
              </div>
          </div>
      </div>
  </div>
</div>


</>

  
  );
}

export default Suporte;
