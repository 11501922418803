import { onAuthStateChanged } from 'firebase/auth';
import Header from './Header';
import { firebaseAuth, storage } from '../utils/firebase-config';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";

function Verificacao() {
  const navigate = useNavigate();
  const [current, setCurrent] = useState('');
  const [imageUpload, setImageUpload] = useState(null);
  const [loading, setLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [uploadCompleted, setUploadCompleted] = useState(false);

  const uploadFile = () => {
    if (!imageUpload) return;

    setLoading(true);

    const imageRef = ref(storage, `clientesMidias/${current}/videoVerificacao`);

    uploadBytes(imageRef, imageUpload)
      .then((snapshot) => {
        getDownloadURL(snapshot.ref).then((url) => {
          setSuccessMessage('Vídeo enviado com sucesso! Aguarde aprovação da nossa equipe.');
          setLoading(false);
          setUploadCompleted(true);
        });
      })
      .catch(error => {
        console.error('Erro ao enviar vídeo:', error);
        setLoading(false);
      });
  };

  onAuthStateChanged(firebaseAuth, (currentUser) => {
    if (currentUser) {
      setCurrent(currentUser.email);
    }
  });

  const handleBack = () => {
    navigate('/anunciante');
  };

  return (
    <>
      <Header />

      <div className="demo10" style={{ height: '100%' }}>
        <div className="container">
          <h4 className="py-4 text-center" style={{ color: '#dcc780', fontSize: '30px', fontWeight: '800' }}>Verificação de Perfil</h4>
          <div className="row" style={{ justifyContent: 'space-evenly' }}>
            <div className="col-md-8 col-sm-6">
              <div className="pricingTable20">
                <div className="pricingTable-header">
                  <h3 className="heading">Envie seu vídeo</h3>
                </div>
                <div className="pricing-content">
                  {loading && (
                    <div className="demo-container">
                      <div className="progress-bar">
                        <div className="progress-bar-value"></div>
                      </div>
                    </div>
                  )}
                  <ul>
                    <li style={{ paddingBottom: '50px', fontWeight: '200' }}>
                      Faça um vídeo curto mostrando seu rosto, segurando uma placa com as seguintes escritas: <br />
                      <span style={{ fontWeight: '600' }}>StudioAcompanhante + Mês e Ano</span><br /><br />
                      Esse vídeo não será publicado no seu anúncio.<br />
                      Caso haja alguma divergência, avisaremos pelo E-MAIL informado no seu cadastro.
                    </li>
                    <li style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                      <input style={{ margin: '20px' }} onChange={(event) => setImageUpload(event.target.files[0])} type='file' />
                    </li>
                  </ul>
                  {!uploadCompleted && (
                    <button href="#" onClick={uploadFile} className="btn button" style={{ margin: '20px' }}>Enviar</button>
                  )}
                  {loading && <p>Enviando vídeo...</p>}
                  {successMessage && <p style={{ color: 'green' }}>{successMessage}</p>}
                  {uploadCompleted && (
                    <button onClick={handleBack} className="btn button" style={{ margin: '20px' }}>Voltar</button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Verificacao;
